export default {
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_KT0IaNseT',
    APP_CLIENT_ID: '73jv4jcd4f9auur10f1anb5qrh',
    BUCKET: 'zipin-shop',
    ACCESS_KEY: 'AKIA2LHBQ4RO2BZQLMG4',
    SECRET_KEY: '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',
  },
};
